import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import get from '../function/get'
import Loading from '../componant/Loading'
import calcEfficatiter from '../function/calcEffecatiter'
import RegleExpedition from '../componant/regles/RegleExpedition'
import patchJson from '../function/patchJson'
import Mesage from '../componant/Mesage'
import Modal from '../componant/Modal'
import Popup from '../componant/Popup'
import Try from '../componant/crew/expedition/Try'
import CustumSelect from '../componant/CustumSelect'
import ZoneText from '../componant/ZoneText'
import useFetchAuth from '../hook/useFetchAuth'
import ImgFetch from '../componant/ImgFetch'
import { authcontext } from '../contexte/AuthContext'


export default function Expedition() {
    const {crewId,expeditonId} = useParams()
    const {authFetch} = useContext(authcontext)
    const [loadding,setLoading] = useState(true)
    const [LoadingSecondary,setLoadingSecondary] = useState(false)
    const fetchAuth = useFetchAuth()
    const [loaddingMision,setLoadingMision] = useState(true)
    const [expedition,setExpedition] = useState()
    const [campagne,setCampagne] = useState()
    const [curentMision,setCurentMison] = useState()
    const [camandant,setComandant] = useState("N/A")
    const [fautif,setFautif] = useState('N/A')
    const [text,setText] = useState('')
    const [message,setMessage] = useState({type:"",message:""})
    const [modal,setModal] = useState(false)
    const nav = useNavigate()
    const [seting,setSeting] = useState([])
    const [showFautif,setShowFautif] = useState(false)
    const [showComandant,setShowComandant] = useState(false)
    const [speakOn,setSpeak] = useState(false)
    const synth = window.speechSynthesis

    

    useEffect(()=>{
        let jeu
        Promise.all([
            authFetch.get(`/crew/expedition/${crewId}/${expeditonId}`).then((expedition)=>{
                setExpedition(expedition)
                return authFetch.get(`/campagne/id/${expedition.campagneRef}`)
            }).then((campagne)=>{
                setCampagne(campagne)
                
                jeu = campagne.jeuBase
                return authFetch.get('/seting')
            }).then((seting)=>{
                setSeting(seting.find((element)=>(element.jeuBase===jeu)))
            })
        ]).then(()=>{
            setLoading(false)
        }).catch((err)=>{
            console.error(err)
            nav('/home')
        })
    },[crewId,expeditonId,nav])

    useEffect(()=>{
        if(!loadding){
            const mision = expedition.progress.find((progres)=>(progres.state === 'in progress'))
            const misionInfo = campagne.misions.find((element)=>(element.number === mision.ref))
            setCurentMison({...mision,...misionInfo})
            setText(misionInfo.lor)
            setLoadingMision(false)
            
        }
    },[expedition,loadding,campagne])

    useEffect(()=>{
        if(synth.speaking){
            setSpeak(false)
            synth.cancel()
        }
        return ()=>{
            if(synth.speaking){
                setSpeak(false)
                synth.cancel()
            }
        }
    },[text,synth])

    useEffect(()=>{
        if(message.message !== ""){
            const timer = setTimeout(()=>{
                setMessage({type:"",message:""})
            },10000)
    
            return ()=>{
                clearTimeout(timer)
            }
        }
    },[message])

    const messageDisplay = (message)=>{
        setMessage(message)
    }

    const closeModal = (e)=>{
        e.stopPropagation()
        setModal(false)
    }

    const reusiModal = ()=>{
        if(camandant === 'N/A'){
            messageDisplay({message:'',type:''})
            messageDisplay({message:'veuillez sélectioner un commandant',type:'nok'})
            return
        }
        setModal( <Popup titel={`Sucess de la mision N°${curentMision.number}`} yes={reusi} no={closeModal}>Etes-vous sur ?  {'('} Commandant: {expedition.expeditionMate.find((mate)=>(mate.crewmateRef === camandant)).name}{')'}</Popup> )
    
    }

    const failModal = ()=>{
        if(camandant === 'N/A'){
            messageDisplay({message:'',type:''})
            messageDisplay({message:'veuillez sélectioner un commandant',type:'nok'})
            return
        }
        
        setModal( <Popup titel={`Echec de la mision N°${curentMision.number}`} yes={fail} no={closeModal}>Etes-vous sur ?  {'('} Commandant: {expedition.expeditionMate.find((mate)=>(mate.crewmateRef === camandant)).name}, fautif: {fautif !=='N/A'?expedition.expeditionMate.find((mate)=>(mate.crewmateRef === fautif)).name:'N/A'}{')'}</Popup> )
    }

    const helpModal = ()=>{
        if(curentMision.help){
            setText(seting.regleDetresse.instruction)
            return
        } 
        setModal( <Popup titel={'Lancer un signal de détresse'} yes={help} no={closeModal}>
            {<div className='contenair--tall contenair contenair--column'>
                   <ZoneText text={seting.regleDetresse.instruction}/>
            </div>}
             Etes-vous sur de vouloir lançer une fusée de détresse il vous en couteras un essai ?</Popup> )
}

    const reusi = (e)=>{
        e.stopPropagation()
        setLoadingSecondary(true)
        closeModal(e)
        if(curentMision.isInfinite === true){
            console.log('infini')
            const param = {
                crewId:crewId,
                expeditionId:expedition.id, 
            }
            authFetch.patchJson('/crew/reusiInfinite',param).then((expedition)=>{

                const misionIndex = expedition.progress.findIndex((progres)=>(progres.state === 'in progress'))
                const mision = expedition.progress[misionIndex]
                const misionInfo = campagne.misions.find((element)=>(element.number === mision.ref))

                setModal(<Popup titel={'succès'} yes={closeModal} no={false}>
                    <div className='contenair contenair--centre'>
                        vous avez reusi la mission N°{misionInfo.number} au rand {mision.attempt - 1}
                    </div>
                    
                </Popup>)
                setExpedition(expedition)
                setLoadingSecondary(false)
                setComandant('N/A')
                setFautif('N/A')
            }).catch((err)=>{
                setLoadingSecondary(false)
                console.error(err)
            })
            return
        }
        const param = {
            crewId:crewId,
            expeditionId:expedition.id,
            fautif:fautif !=='N/A'?expedition.expeditionMate.find((mate)=>(mate.crewmateRef === fautif)):{crewmateRef:'N/A',type:'none'},
            comandant:camandant !=='N/A'?expedition.expeditionMate.find((mate)=>(mate.crewmateRef === camandant)):{crewmateRef:'N/A',type:'none'} 
        }
    authFetch.patchJson('/crew/reusi',param) 
        .then((expedition)=>{
            
            if(expedition.done){
                setModal(
                    <Popup titel={'Succés'} yes={()=>{nav('/home')}} no={false}>
                        <div className='contenair contenair--centre'>
                            Bravo!! vous avez reusi les {expedition.numberOfMision} mission avec une efficacité de {calcEfficatiter(expedition.stats.sucess,expedition.stats.attempt)}%
                        </div>
                        <table>
                            <thead>
                                
                                <tr>
                                    <th colSpan={'5'}>Statistique des joueurs</th>
                                </tr>
                                <tr>
                                    <th>Joueur</th>
                                    <th>Comandant</th>
                                    <th>Comandant %</th>
                                    <th>Fautif</th>
                                    <th>Fautif %</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expedition.expeditionMate.map((mate)=>{
                                    console.log(mate)
                                    return(
                                        <tr>
                                            <td>{mate.name}</td>
                                            <td>{mate.stats.comanderFail+ mate.stats.comanderSucess}</td>
                                            <td>{calcEfficatiter(mate.stats.comanderSucess,mate.stats.comanderFail+ mate.stats.comanderSucess)}</td>
                                            <td>{mate.stats.fautif}</td>
                                            <td>{calcEfficatiter(expedition.stats.sucess,mate.stats.fautif +expedition.stats.sucess)}</td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>                    
                    </Popup>
                )
                setLoadingSecondary(false)
                return
            }
            const misionIndex = expedition.progress.findIndex((progres)=>(progres.state === 'in progress'))
            const mision = expedition.progress[misionIndex-1]
            const misionInfo = campagne.misions.find((element)=>(element.number === mision.ref))
            setModal(<Popup titel={'succès'} yes={closeModal} no={false}>
                <div className='contenair contenair--centre'>
                    vous avez reusi la mission N°{misionInfo.number} avec une efficacité de {calcEfficatiter(1,mision.attempt.length)}%
                </div>
                <table>
                    <thead>
                        
                        <tr>
                            <th colSpan={'5'}>Statistique des joueurs</th>
                        </tr>
                        <tr>
                            <th>Joueur</th>
                            <th>Comandant</th>
                            <th>Comandant %</th>
                            <th>Fautif</th>
                            <th>Fautif %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expedition.expeditionMate.map((mate)=>{
                            console.log(mate)
                            return(
                                <tr>
                                    <td>{mate.name}</td>
                                    <td>{mate.stats.comanderFail+ mate.stats.comanderSucess}</td>
                                    <td>{calcEfficatiter(mate.stats.comanderSucess,mate.stats.comanderFail+ mate.stats.comanderSucess)}</td>
                                    <td>{mate.stats.fautif}</td>
                                    <td>{calcEfficatiter(expedition.stats.sucess,mate.stats.fautif +expedition.stats.sucess)}</td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </Popup>)
            setExpedition(expedition)
            setLoadingSecondary(false)
            setComandant('N/A')
            setFautif('N/A')
        }).catch((err)=>{
            console.error(err)
            setLoadingSecondary(false)
        })
    }

    const fail = (e)=>{
        e.stopPropagation()
        setLoadingSecondary(true)
        closeModal(e)
        if(camandant === 'N/A'){
            messageDisplay({message:'veuillez sélectioner un commandant',type:'nok'})
            return
        }
        if(curentMision.isInfinite === true){
            const param = {
                crewId:crewId,
                expeditionId:expedition.id, 
            }
            authFetch.patchJson('/crew/failInfinite',param)
            .then((expedition)=>{
                setLoadingSecondary(false)
                setExpedition(expedition)
                setComandant('N/A')
                setFautif('N/A')
            }).catch((err)=>{
                messageDisplay({message:'Une erreur est survenue veiller réessayer',type:'nok'})
                setLoadingSecondary(false)
                console.error(err)
            })
            
            return
        }
        const param = {
            crewId:crewId,
            expeditionId:expedition.id,
            fautif:fautif !=='N/A'?expedition.expeditionMate.find((mate)=>(mate.crewmateRef === fautif)):{crewmateRef:'N/A',type:'none'},
            comandant:camandant !=='N/A'?expedition.expeditionMate.find((mate)=>(mate.crewmateRef === camandant)):{crewmateRef:'N/A',type:'none'} 
        }
        authFetch.patchJson('/crew/fail',param)
        .then((expedition)=>{
            closeModal(e)
            setExpedition(expedition)
            setComandant('N/A')
            setFautif('N/A')
            setLoadingSecondary(false)
        }).catch((err)=>{
            setLoadingSecondary(false)
            messageDisplay({message:'Une erreur est survenue veiller réessayer',type:'nok'})
            console.error(err)
            
        })
    }

    const help = (e)=>{
        e.stopPropagation()
        if(curentMision.isInfinite === true){
            return
        }
        const param = {
            crewId:crewId,
            expeditionId:expedition.id,
        }
        authFetch.patchJson('/crew/help',param)
        .then((expedition)=>{
            closeModal(e)
            setExpedition(expedition)
        }).catch((err)=>{
            console.error(err)
        })   
    }

    const speak = ()=>{
        if(synth.speaking){
            synth.cancel()
            setSpeak(false)
            return
        }
        const specker = new SpeechSynthesisUtterance(text)
        synth.speak(specker)   
        setSpeak(true)
        specker.onend = (event)=>{
            setSpeak(false)
        }     
    }

    const openFautif = (e)=>{
        console.log('open')
        setShowFautif(true)
        /* document.addEventListener('click',closeFautif) */
    }

    const closeFautif = (e)=>{
        console.log('close |',e.target)
        e.stopPropagation()
        setShowFautif(false)
       /*  document.removeEventListener('click',closeFautif) */
    }

    const openComandant = (e)=>{
        console.log('open')
        setShowComandant(true)
        /* document.addEventListener('click',closeFautif) */
    }

    const closeComandant = (e)=>{
        console.log('close |',e.target)
        e.stopPropagation()
        setShowComandant(false)
       /*  document.removeEventListener('click',closeFautif) */
    }

    if((loadding)){
        return <Loading/>
    }
    if(loaddingMision){
        return <Loading/>
    }
  return (
    <div className='contenair contenair--tall contenair--centre '>

        <div className='expedition__warpper grid'>
            {modal !== false ? <Modal className='modal--centre' toggleModal={closeModal}>{modal}</Modal> : null} 
            <div className='expedition__info contenair' >
                <div className='expedition__name titel'>{`${campagne.jeuBase} - ${campagne.name}`}</div>
                {curentMision.isInfinite === true ? curentMision.maxAttempt > 0 ? <div className='expedition__info__campagne__efficatiter contenair contenair--centre'>Meilleur score: {curentMision.maxAttempt}</div>: <div className='expedition__info__campagne__efficatiter contenair contenair--centre'>Vous n'avez pas encore reusi</div> : <div className='expedition__info__campagne__efficatiter contenair contenair--centre'>{calcEfficatiter(expedition.stats.sucess,expedition.stats.attempt)}%</div>}
            </div>
            <div className='expedition__header'>
                <Mesage message={message}/>
                <div className='expedition__crewmate contenair contenair--space-evently'>
                    {expedition.expeditionMate.map((mate)=>(
                        <div key={mate.crewmateRef} className='expedition__mate'>
                            <div className='expedition__mate__name '>{mate.name}</div>
                        </div>
                    ))}
                </div>
            </div>
                <div className='expedition__text contenair contenair--column'>
                    <div className='contenair--scroll expedition__text__content'>{text}</div> 
                    <div className='expedition__text__speak' onClick={speak}>
                        {speakOn ? <img src='/speakOff.png' alt='speak on'/>: <img src='/speakOn.png' alt='speak off'/>}
                    </div>
                    
                </div>
                <div className='expedition__side contenair contenair--column contenair--column--centre overflow--hidden'>
                    {curentMision.dificulty !==0 && curentMision.isInfinite !== true ? <div className='expedition__side__dificulty__img contenair contenair--centre' onClick={()=>{setText(seting.regleDificulty.instruction)}}> <ImgFetch src={seting.dificultyDir+seting.dificultyName+curentMision.dificulty+seting.dificultyExt} alt="difficulté"/></div> : null}
                    {curentMision.isInfinite ? curentMision.type ==="autre" ? <div className='expedition__side__dificulty__img contenair contenair--centre' onClick={()=>{setText(seting.regleDificulty.instruction)}}> <ImgFetch src={'/img/infini/'+'_'+curentMision.attempt+'.png'} alt="difficulté"/></div> : <div className='expedition__side__dificulty__img contenair contenair--centre' onClick={()=>{setText(seting.regleDificulty.instruction)}}> <ImgFetch src={seting.dificultyDir+seting.dificultyName+curentMision.attempt+seting.dificultyExt} alt="difficulté"/></div> : null}
                    <div className='expedition__regle contenair contenair--centre contenair--scroll'>
                        {curentMision.regles.filter((regle)=>(!regle.optional)).map((regle,index)=>(
                            <RegleExpedition setText={setText} key={`regle/${index}`} regle={regle}/>
                        ))}
                    </div>
                    {curentMision.regles.find(((regle)=>(regle.optional)))?
                      <div className='contenair contenair--column'>
                        <div className='expedition__regle__separateur contenair contenair--centre'>Ou</div>
                        <div className='expedition__regle contenair contenair--centre contenair--scroll'>
                            {curentMision.regles.filter((regle)=>(regle.optional)).map((regle,index)=>(
                                <RegleExpedition setText={setText} key={`regleOptional/${index}`} regle={regle}/>
                            ))}
                        </div>
                      </div>:null  
                    }
                    
                </div>
            
                
                    <div className='expedition__controle contenair contenair--centre' action="">
                        <div className='expedition__controle__select contenair contenair--centre contenair--space-evently'>
                            <div className='expedition__controle__mision contenair contenair--centre expedition__controle__btn'>
                                <button className='btn btn--round  expedition__controle__mision__number btn--small' onClick={()=>{setText(curentMision.lor)}}>{curentMision.number}</button>
                                {curentMision.isInfinite ? curentMision.type === 'autre'? <div>Rang max : {curentMision.maxAttempt}</div> : null :<div>{calcEfficatiter(1,curentMision.attempt.length + 1)}%</div>}
                            </div>
                            
                                    {LoadingSecondary ? <div><Loading/></div> : <button onClick={failModal} className='btn btn--content expedition__controle__btn btn--transparent'><img src="/echec.svg" alt="echec" /></button>}
                                    {LoadingSecondary ? <div><Loading/></div> : <button onClick={helpModal} className='btn btn--content expedition__controle__btn'> <ImgFetch src={`${seting.signalDir}${seting.signalName}${curentMision.help ? 'on':'off'}${seting.signalExt}`} alt=""/> </button>}
                                    {LoadingSecondary ? <div><Loading/></div> : <div className='expedition__controle__btn expedition__controle__btn--relative' >
                                        <ImgFetch src={"/img/public/iconne commandant.png"} alt={'comandant'} onClick={openComandant}/>
                                        {showComandant ? <CustumSelect close={closeComandant} className='--relative' select={setComandant} options={expedition.expeditionMate.map((mate)=>({data:mate.crewmateRef,html:<div key={`comandant/${mate.crewmateRef}`}>{mate.name}</div>}))}/>:null}
                                    </div>}
                                   {LoadingSecondary ? <div><Loading/></div> :  <div className='expedition__controle__btn expedition__controle__btn--relative'>
                                        <ImgFetch src={"/img/public/qui est le fautif.png"} alt="fautif" onClick={openFautif}/>
                                        {showFautif ? <CustumSelect close={closeFautif} className='--relative' select={setFautif} options={expedition.expeditionMate.map((mate)=>({data:mate.crewmateRef,html:<div key={`fautif/${mate.crewmateRef}`}>{mate.name}</div>}))}/>:null}
                                    </div>}
                                    {LoadingSecondary ? <div><Loading/></div> : <button onClick={reusiModal} className='btn btn--content expedition__controle__btn btn--transparent'><img src="/valider.svg" alt="valider" /></button>}
                                
                            
                            
                            
                        </div>
                    </div>
                    {
                        curentMision?.isInfinite === true ? 
                        <div className='expedition__attempt contenair contenair--centre'>

                        </div> :
                        <div className='expedition__attempt contenair contenair--centre'>
                            {curentMision.attempt.map((attempt,index)=>{
                                attempt.fautif = attempt.fautif === "" ? "N/A" : attempt.fautif
                                attempt = {...attempt,
                                    fautif: attempt.fautif !== 'help' && attempt.fautif !== 'N/A' && attempt.fautif !== undefined  ? expedition.expeditionMate.find((mate)=>(mate.crewmateRef === attempt.fautif)).name: attempt?.fautif ?? "N/A",
                                    comander: attempt.fautif !== 'help' ? expedition.expeditionMate.find((mate)=>(mate.crewmateRef === attempt.comander)).name : null,
                                    number:index,
                                    help: attempt.fautif === 'help' ? true : false
                                }
                                return <Try key={`attempt/${index}`} attempt={attempt}/>
                            })}
                        </div> 
                        
                    }
                    
                
            
            
        </div>
    </div>
  )
}
